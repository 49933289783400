import React from 'react';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
}            from '@chakra-ui/react';

import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia  from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Center       from '@interness/web-core/src/components/structure/Center/Center';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';


const ServicePage = (props) => {
  return (
    <>
      <HeaderMedia id="header-service"/>
      <Spacer/>
      <Wrapper>
        <Heading>Service</Heading>
        <Box textAlign="justify">
          <h2>Service und Kundennähe</h2>
          <p>Guten Service zu leisten ist für uns eine Selbstverständlichkeit. Das bedeutet eine individuelle und
            fachkundige Beratung im Vorfeld Ihres Bauvorhabens. Eine umfassende Planung Ihres Projektes unter
            Berücksichtigung Ihrer individuellen Wünsche und Vorstellungen. Das bedeutet auch Dachinspektionen. Auf
            Wunsch
            führen wir eine regelmäßige Begehung der Dachflächen, die Ausarbeitung eines Inspektionsberichtes sowie,
            falls
            erforderlich, die Erarbeitung von Vorschlägen für Pflege- und Instandsetzungsmaßnahmen nebst einer
            entsprechenden Kostenübersicht durch.</p>
          <p>Wir sind für Sie da, gerade wenn es in dringenden Fällen zur kurzfristigen Beseitigung von Schäden durch
            Sturm oder Unwetter mit extremen Regenfällen, notwendig ist.</p>
          <Spacer/>
          <h2>Krangestellung</h2>
          <p>Zu unserm Service gehört auch ein moderner Kran, der es uns ermöglicht Material und Mitarbeiter schnell und
            sicher auch in höhere Lagen zu befördern. Auch bei größeren Umbauarbeiten am Dach, Zimmerarbeiten und
            Dachstuhlmontage, stehen wir Ihnen somit schnell zur Verfügung</p>
        </Box>
        <Heading tag="h2">Was wir sonst noch für SIe tun können</Heading>
        <Accordion allowToggle>
          <AccordionItem>
            <h4 style={{ marginBottom: 0 }}>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Reparatur-Sofort-Service
                </Box>
                <AccordionIcon/>
              </AccordionButton>
            </h4>
            <AccordionPanel>
              Kleinere dringende Reparaturen führen wir sofort nach Auftragserteilung innerhalb 24 Std. durch.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h4 style={{ marginBottom: 0 }}>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Dachnotdienst
                </Box>
                <AccordionIcon/>
              </AccordionButton>
            </h4>
            <AccordionPanel>
              In Notfällen (Unwetterschäden, Brandschäden etc.) erreichen Sie uns täglich 24 Std. auch an Sonn- und
              Feiertagen 365 Tage im Jahr.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h4 style={{ marginBottom: 0 }}>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Dachwartungen
                </Box>
                <AccordionIcon/>
              </AccordionButton>
            </h4>
            <AccordionPanel>
              Wir bieten Ihnen flexible Dachwartungsverträge zugeschnitten auf Ihr Objekt. Eine Dachwartung umfasst das
              Reinigen aller Dachentwässerungen (Dachrinnen und Flachdachgullis und Abläufe) und Dachflächen, Kontrolle
              der Dacheindeckungen, Kamin- und Fassadeneindeckungen sowie zu Dachflächenfenstern und Dachaufbauten. Zu
              dem
              werden nach jeder Wartung Wartungsprotokolle erstellt.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Spacer/>
        <Center>
          <CallToAction/>
        </Center>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default ServicePage;